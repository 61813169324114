/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js'
import '@fortawesome/fontawesome-free/css/all.css'
import './components/slick'
import './components/tc-differences'
import './components/video-button'
import './components/select2'
import './components/sign-up-button'
import './components/brigade-tab'
import './components/profile_navigation'
import './components/pdo-note'
import './components/collection-opened'
import './components/gallery-slider'
import './javascripts/age_verification'
import 'simplebar'
import 'simplebar/dist/simplebar.css'

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
