import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.addEventListener('DOMContentLoaded', () => {
  const notDisplayCases = ['individual', 'household', 'pleaseselectone']
  const selectField = $('.user_profiles_organization_type').find('.bootstrapForm_input select')
  const restrictedOrganizations = document.querySelectorAll('[data-global-name="individual/household"]')
  const arrayOfRestricted = Array.from(restrictedOrganizations, option => option.value)

  // Initialize Select2 for organization select fields
  const initializeSelect2 = () => {
    $('.bootstrapForm_formGroup select:not(.stateSelect, .user_profiles_organization_type select)').select2({
      minimumResultsForSearch: -1
    })
  }

  const toggleSicCodeFieldVisibility = (selectedText) => {
    const text = selectedText.toLowerCase().replace(/\s/g, '')
    const shouldShow = !notDisplayCases.includes(text)
    $('.user_profiles_sic_code').parent().toggle(shouldShow)
  }

  const handleOrganizationSelect = (e) => {
    const selectedText = $(e.currentTarget).find(':selected').text()
    const selectedValue = $(e.currentTarget).find(':selected').val()
    const genderId = $('.user_gender_id')
    const ageRangeId = $('.user_profiles_age_range_id')

    if (arrayOfRestricted.includes(selectedValue)) {
      genderId.show()
      ageRangeId.show()
    } else {
      genderId.hide()
      ageRangeId.hide()
      resetSelectFields(genderId, ageRangeId)
    }

    toggleSicCodeFieldVisibility(selectedText)
  }

  const resetSelectFields = (...fields) => {
    fields.forEach(field => field.find('select').val(null).trigger('change'))
  }

  // Call functions to initialize and setup event handlers
  initializeSelect2()
  $('.user_gender_id, .user_profiles_age_range_id').hide()
  toggleSicCodeFieldVisibility(selectField.find(':selected').text())
  selectField.on('change', handleOrganizationSelect)
})
